.video_container{
    grid-template-columns: var(--col-1);
    opacity: 0;
    z-index: 0;
    background-color: #000;
}

.video_container .controls {
    left: 100px;
    bottom: 110px;
    width: 60px;
    height: 60px;
    z-index: 10;
}

.video_container .controls .actions {
    background-color: var(--color-placeholder);
    opacity: .8;
    border-radius: 50%;
    grid-template-columns: var(--col-1);
}

.video_container .controls .actions .progress{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    grid-template-columns: var(--col-1);
}

.video_container .controls .actions .progress svg{
    place-self: var(--fadrines-center);
    transition: all .5s ease-in-out;
}

.video_container .controls .actions button{
    background-color: transparent;
    border: none;
    place-self: center;
    z-index: 10;
}

.video_container .controls .actions button svg{
    width: 20px;
    height: 20px;
    fill: var(--color-light);
}

.subtitle{
    position: var(--position-absolute) !important;
    z-index: 20 !important;
    left: 50px !important;
    bottom: 100px !important;
    cursor: var(--cursor-pointer);
}

.dynamic_text{
    top: 50%;
    left: 30%;
    transform: translate(-50%, -31%);
    background-color: var(--color-pink) !important;
    text-transform: lowercase !important;
    font-weight: bold;
}

.CircularProgressbar{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.credit_text {
    color: #fff;
    text-align: center;
    font-size: 1.10rem;
    width: 100%;
    margin: 10px 0px;
}

.credit_text strong{
    font-size: 1.3rem;
    font-weight: bold;
}
