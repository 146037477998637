
.container-animation-class{
    animation: container 1.5s linear alternate;
    -moz-animation: container 1.5s linear alternate;
    -webkit-animation: container 1.5s linear alternate;
}

@keyframes container {
    0% {
        opacity: 0;
        z-index: 0;
    }
    50% {
        opacity: 1;
        z-index: 100;
    }
    100% {
        opacity: 0; 
        z-index: 0;
    }
}

@-webkit-keyframes container {
    0% {
        -webkit-opacity: 0;
    }
    50% {
        -webkit-opacity: 1;
    }
    100% {
        -webkit-opacity: 0;
    }
}

@-moz-keyframes container {
    0% {
        -moz-opacity: 0;
    }
    50% {
        -moz-opacity: 1;
    }
    100% {
        -moz-opacity: 0; 
    }
}

@keyframes betweenImages {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1; 
    }
}

@-webkit-keyframes betweenImages {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1; 
    }
}

@keyframes first {
    from { opacity: 0; }
    to { opacity: 1; }
}

@-webkit-keyframes first {
    from { -webkit-opacity: 0; }
    to { -webkit-opacity: 1; }
}

@-moz-keyframes first {
    from { -moz-opacity: 0; }
    to { -moz-opacity: 1; }
}

@keyframes second {
    from { opacity: 1; }
    to { opacity: 0; }
}

@-webkit-keyframes second {
    from { -webkit-opacity: 1; }
    to { -webkit-opacity: 0; }
}

@-moz-keyframes second {
    from { -moz-opacity: 1; }
    to { -moz-opacity: 0; }
}