.special {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    grid-template-columns: 1fr;
}

.special > div {
    place-self: center;
    display: var(--webkit-display-flex);
    flex-direction: var(--flex-direction-column);
    justify-content: center;
    align-items: center;
}

.special .special_body{
    margin-bottom: 5rem;
}

.special .special_body p{
    font-size: var(--font-size-17);
}

.special .special_body img{
    width: 50px;
    height: 50px;
}

.special .special_footer{
    display: var(--webkit-display-flex);
    flex-direction: var(--flex-direction-row);
    justify-content: center;
}

.special span {
    color: var(--color-pink);
    font-weight: bold;
}