.color_blue {
    background-color: rgb(36, 129, 250);
}

.handleText3_4{
    left: 15% !important;
    transform: translateY(-50%) !important;
}

.handleText8 {
    background-color: rgb(252, 84, 12);
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.center_text {
    top: 50%;
    left: 30%;
    transform: translate(-50%, -31%);
}