.container{
    left: 0;
    bottom: 130px;
    z-index: 50;
}

.body{
    transition: all 0.4s ease-in-out;
}

.option {
    background-color: var(--color-primary);
    color: var(--color-light);
    font-size: .7rem;
    font-weight: lighter;
    padding: 5.5px;
    transition: all 0.4s ease-in-out;
}

.active{
    left: 0px;
    position: var(--position-absolute);
}

.inactive{
    left: -78px;
    position: var(--position-absolute);
}