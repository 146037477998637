.text_mark{
    align-items: flex-end;
    top: 50%;
    left: 15%;
    transform: translateY(-50%);      
}

.text_mark p {
    margin: 0px 10px;
    color: var(--color-light);
    font-size: 1.25rem;
}