:root{
  --color-purple: rgb( 105, 0, 254);
  --color-primary: rgb(35, 0, 255);
  --color-light: #fff;
  --color-black: #000;
  --color-background-text: rgb(40, 6, 208);
  --color-placeholder: #696969;
  --color-pink: rgb(251, 0, 98);
  --webkit-display-flex: flex;
  --flex-direction-row: row;
  --flex-direction-column: column;
  --display-block: block;
  --webkit-display-grid: grid;
  --col-1: 1fr;
  --col-2: repeat(2, 1fr);
  --col-3: repeat(3, 1fr);
  --col-5: repeat(5, 1fr);
  --col-12: repeat(12, 1fr);
  --breakpoint-size-xs: 768px;
  --breakpoint-size-sm: 992px;
  --breakpoint-size-md: 1200px;
  --scale-xs: scale(1.05);
  --scale-sm: scale(1.25);
  --scale-md: scale(1.50);
  --scale-lg: scale(1.75);
  --position-absolute: absolute;
  --position-relative: relative;
  --position-fixed: fixed;
  --cursor-auto: url('/public/images/icono-cursor.svg'),auto;
  --cursor-pointer: url('/public/images/icono-cursor-hover.svg'),auto;
  --fadrines-center: center;

  --font-size-10: .625rem;
  --font-size-12: .75rem;
  --font-size-13: .8125rem;
  --font-size-15: .9375rem;
  --font-size-17: 1.0625rem;
  --font-size-20: 1.25rem;
  --font-size-25: 1.5625rem;
  --font-size-150: 9.375rem;

}

@keyframes white-to-blue{
  from{
    background-color: var(-color-light);
  }
  to{
    background-color: var(-color-primary);
  }
}

@keyframes blue-to-white{
  from{
    background-color: var(-color-primary);
  }
  to{
    background-color: var(-color-light);
  }
}

@keyframes between-images{
  0%{
    opacity: .1;
  }
  25%{
    opacity: .25;
  }
  50%{
    opacity: .5;
  }
  75%{
    opacity: .75;
  }
  100%{
    opacity: 1;
  }
}

html {
  font-size: 100%;
}

html, body{
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

body {
  margin: 0;
  font-family: 'Roboto Slab', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: var(--cursor-auto);
  overflow: hidden;  
}

video::cue {
  font-size: var(--font-size-25);
  color:#fff;
}

a, button, select {
  cursor: var(--cursor-pointer);
}



/* Examples animations */
.hide {
  -webkit-animation: fadeinout .3s linear forwards;
  animation: fadeinout .3s linear forwards;
}

@-webkit-keyframes fadeinout {
  0% { opacity: 0.6; }
  50% { opacity: 0.2; }
  100% { opacity: 0; }
}

@keyframes fadeinout {
  0% { opacity: 0.6; }
  50% { opacity: 0.2; }
  100% { opacity: 0; }
}

.show {
  -webkit-animation: display .5s linear forwards;
  animation: display .5s linear forwards;
}


@-webkit-keyframes display {
  0% { opacity: 0.2; }
  50% { opacity: 0.6; }
  100% { opacity: 1; }
}

@keyframes display {
  0% { opacity: 0.2; }
  50% { opacity: 0.6; }
  100% { opacity: 1; }
}