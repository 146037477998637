.images{
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 10px;
    animation: first 1s linear alternate;
    -moz-animation: first 1s linear alternate;
    -webkit-animation: first 1s linear alternate;
}

@keyframes first {
    0% { opacity: 0; }
    50% { opacity: 0;}
    100% { opacity: 1; }
}

@-webkit-keyframes first {
    0% { -webkit-opacity: 0; }
    50% { -webkit-opacity: 0;}
    100% { -webkit-opacity: 1; }
}

@-moz-keyframes first {
    0% { -moz-opacity: 0; }
    50% { -moz-opacity: 0;}
    100% { -moz-opacity: 1; }
}

.images img, video{
    width: 100%;
    height: 100%;
}


.dynamic_text{
    top: 50%;
    left: 30%;
    transform: translate(-50%, -31%);
    background-color: var(--color-purple);
}

.dynamic_text_blue {
    background-color: rgb(20, 18, 102);
}

.special_dynamic_text{
    background-color: var(--color-pink);
    text-transform: lowercase !important;
    font-weight: bold;
}

.special_dynamic_text_1{
    left: 50%;
    width: max-content;
}

@keyframes appear_text {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1; 
    }
}
@-webkit-keyframes appear_text {
    0% {
        -webkit-opacity: 0;
    }
    50% {
        -webkit-opacity: 0;
    }
    100% {
        -webkit-opacity: 1; 
    }
}
@-moz-keyframes appear_text {
    0% {
        -moz-opacity: 0;
    }
    50% {
        -moz-opacity: 0;
    }
    100% {
        -moz-opacity: 1; 
    }
}

.special_dynamic_text_2{    
    margin-top: 50px;
}

.button_modal {
    border: none;
    display: var(--webkit-display-flex);
    flex-direction: row;
    align-items: center;
}

.button_modal:hover {
    background-color: transparent;
}

.button_modal > div {
    background-color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
}

.button_modal > div svg {
    width: 30px;
    height: 30px;
}

.logo {
    z-index: 10;
    grid-column: 12 / 13;
    place-self: end flex-end;
    display: flex;
    justify-content: flex-end;
    margin: 0px 25px 25px 0px;
    width: 100%;
}

.logo img {
    width: 25%;
    height: 25%;
}

.arrow {
    width: 30px !important;
    height: 30px !important;   
    filter:drop-shadow(1px 1px 3px grey);
    margin-top: 20px;
}

.background_white {
    background-color: var(--color-light);
}

.background_blue {
    background-color: var(--color-primary)
}

.transition {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.transitionActive{
    -moz-animation: container 1s linear alternate;
    -webkit-animation: container 1s linear alternate;
    animation: container 1s linear alternate;
}

@keyframes container {
    0% {
        opacity: 0;
        z-index: 0;
    }
    50% {
        opacity: 1;
        z-index: 100;
    }
    60% {
        opacity: 1;
        z-index: 100;
    }
    100% {
        opacity: 0; 
        z-index: 0;
    }
}

@-webkit-keyframes container {
    0% {
        -webkit-opacity: 0;
        z-index: 0;
    }
    50% {
        -webkit-opacity: 1;
        z-index: 100;
    }
    60% {
        -webkit-opacity: 1;
        z-index: 100;
    }
    100% {
        -webkit-opacity: 0;
        z-index: 0;
    }
}

@-moz-keyframes container {
    0% {
        -moz-opacity: 0;
        z-index: 0;
    }
    50% {
        -moz-opacity: 1;
        z-index: 100;
    }
    60% {
        -moz-opacity: 1;
        z-index: 100;
    }
    100% {
        -moz-opacity: 0; 
        z-index: 0;
    }
}

.know_more {
    z-index: 10;
    width: 55px;
    cursor: var(--cursor-pointer);
    position: var(--position-absolute);
    top: 70vh;
    left: 80vw;
}

.know_more svg {
    width: 100%;
    height: 100%;
}

.special_footer{
    flex-direction: row !important;
}

.change_color_know_more{
    left: 10vw;
}

.change_color_know_more polygon,
.change_color_know_more path {
    fill: #be5acc !important;
}

.new_color{
    background-color: #be5acc;
}

.new_animation {
    animation: first 4s linear alternate;
}

.skip_video{
    position: absolute;
    right: 5%;
    bottom: 100px;
    z-index: 10;
}