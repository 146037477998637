.container{
    grid-template-columns: var(--col-1);
    left: 30px;
    bottom: 15px;
}

.container a {
    width: 20px;
    height: 20px;
    margin: 3px 0px;
}