.audio_controller{
    z-index: 10;
    bottom: 7px;
    left: 8px;
    align-items: flex-start;
}

.audio_controller .handle_audio{
    background-color: var(--color-light);
    border-radius: 8px;
    padding: 5px 10px;
    margin-bottom: 10px;
}

.audio_controller .indicator{
    margin-left: 5px;
}