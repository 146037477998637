.container_home {
    height: 100vh;
    width: 100vw;
    display: var(--webkit-display-grid);
    grid-template-columns: var(--col-12);
    position: var(--position-relative);
}

.container_home::before{
    position: var(--position-absolute);
    content: '';
    width: 100%;
    height: 100%;
    background-image: url("/public/images/bg-09.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    animation: leaves 7s ease-in-out infinite alternate;
    z-index: -1;
}

.container_home .container_home__content{
    grid-column: 5/9;
    display: var(--webkit-display-flex);
    flex-direction: var(--flex-direction-column);
    justify-content: space-between;
    height: 60vh;
    margin: auto;
}

.container_home .container_home__content .header{
    display: var(--webkit-display-flex);
    flex-direction: var(--flex-direction-column);
    justify-content: center;
    width: 80%;
    margin: 20px auto;
}

.container_home .container_home__content .header img{
    margin: 15px auto;
}

.container_home .container_home__content .header .text{
    color: var(--color-light);
    text-align: center;
    font-size: var(--font-size-15);
    white-space: pre-wrap;
}

.container_home .container_home__content .body{
    margin: 10px auto;
}

.container_home .container_home__content .footer{
    margin: 10px auto;
}

.container_home .container_home__content .footer > div{
    display: var(--webkit-display-flex);
    flex-direction: var(--flex-direction-row);
    align-items: center;
    justify-content: space-around;
    color: var(--color-light);
    transition: all .3s ease-in-out;
    padding: 0px 20px;
    text-transform: uppercase;
    font-size: var(--font-size-13);
    position: var(--position-relative);
}

.container_home .container_home__content .footer > div::before{
    position: var(--position-absolute);
    content: " ";
    background-image: url("../../assets/corchete-izquierda.svg");
    width: 21px;
    height: 72px;
    left: 0;
}

.container_home .container_home__content .footer > div::after{
    position: var(--position-absolute);
    content: " ";
    background-image: url("../../assets/corchete-derecha.svg");
    transition: all .5s ease-in-out;
    width: 21px;
    height: 72px;
    right: 0;
}

.container_home .container_home__content .footer > div span{
    margin-left: 10px;
}

.container_home .container_home__content .footer div:hover{
    transform: scale(.9);
    cursor: var(--cursor-pointer);
}

.container_home .container_home__content .footer > div .arrow{
    background-image: url('../../assets/flecha-blanca.svg');
    width: 60px;
    height: 60px;
    transition: all .3s ease-in-out;
}

.logos{
    grid-column: 11/span12;
    place-self: end;
    display: var(--webkit-display-flex);
    flex-direction: var(--flex-direction-row);
    margin-bottom: 20px;
    margin-right: 20px;
}

.logos img{
    width: 40%;
    margin: 20px auto;
}

@keyframes leaves {
    0% {
        transform: scale(1.0);
    }
    100% {
        transform: scale(1.1);
    }
}

.button_selected {
    background-color: var(--color-light) !important;
    color: var(--color-primary) !important;
}


.special_footer{
    position: absolute;
    z-index: 50;
    right: 5%;
    bottom: 100px;
}


.video_container{
    grid-template-columns: var(--col-1);
    opacity: 1;
    z-index: 10;
    background-color: #000;
}

.controls {
    left: 100px;
    bottom: 145px;
    width: 60px;
    height: 60px;
}

.actions {
    background-color: var(--color-placeholder);
    opacity: .8;
    border-radius: 50%;
    grid-template-columns: var(--col-1);
}

.progress{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    grid-template-columns: var(--col-1);
}

.progress svg{
    place-self: var(--fadrines-center);
    transition: all .5s ease-in-out;
}
.actions button{
    background-color: transparent;
    border: none;
    place-self: center;
    z-index: 10;
}

.actions button svg{
    width: 20px;
    height: 20px;
    fill: var(--color-light);
}

.subtitle{
    position: var(--position-absolute) !important;
    z-index: 20 !important;
    left: 50px !important;
    bottom: 100px !important;
    cursor: var(--cursor-pointer);
}

.dynamic_text{
    top: 50%;
    left: 30%;
    transform: translate(-50%, -31%);
    background-color: var(--color-pink) !important;
    text-transform: lowercase !important;
    font-weight: bold;
}

.CircularProgressbar{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.logo {
    position: absolute;
    width: 30px;
    right: 35px;
    bottom: 35px;
    z-index: 50;
}

.logo img {
    width: 100%;
    height: 100%;
}

.menu {
    left: 2px;
    top: 30px;
}

.custom_text {
    color: var(--color-light) !important;
}

.expand {
    top: 50%;
    transform: translateY(-50%) !important;
    margin-left: 15px;
}

.images_description > img {
    width: 15% ;
}

.images_description > span {
    color: white;
    bottom: -30px;
    left: 20%;
    position: absolute;
    font-size: .8rem;
    white-space: nowrap;
    opacity: 0;
}

.images_description:hover span {
    opacity: 1;
    transition: opacity .5s ease-in-out;
}