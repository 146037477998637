.know_more__text {
    color: var(--color-light);
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
    width: 100px;
    margin: 0 0 0 10px;
    transition: all 0.5s ease-in-out;
    opacity: 0;
}

.animationInOut {
    animation: zoomInZoomOut 1s infinite forwards;
}
.animationInOut:hover{
    animation: none;
}
.animationInOut:hover p{
    opacity: 1;
}

@keyframes zoomInZoomOut {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.20);
    }
    100% {
        transform: scale(1);
    }
}