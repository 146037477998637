.cls-white{
    fill:#fff;
}
.cls-pink{
    fill:#ff0e75;
}
.cls-purple{
    fill: #be5acc;
}
.cls-yellow{ 
    fill:#d3ca29;
}
.cls-orange {
    fill:#ff6b07;
}