.sentence{
    top: 50%;
    left: 50%;
    width: 40%;
    transform: translate(-50%, -50%);
    color: var(--color-light);
    text-align: end;
    font-weight: 700;
    animation: appear 2.5s linear alternate;
}

.sentence .text{
    font-size: var(--font-size-20);
    text-align: var(--fadrines-center);
}

.sentence .signature{
    font-size: var(--font-size-12);
    letter-spacing: 2px;
}

@keyframes appear {
    from { opacity: 0; }
    to { opacity: 1; }
}