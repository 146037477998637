.menu{
    width: 100px;
}

.menu:hover .menu_item .menu_item_content {
    display: var(--display-block);
    font-size: .6rem;
    letter-spacing: 1px;
    margin: 5px 30px;
}

.menu:hover .menu_item .social{
    display: var(--webkit-display-flex);
}

.menu_item {
    align-content: center;
}

.menu_item:hover .menu_item_content {
   color: var(--color-pink) !important;
}

.menu_item:hover .item {
    background-color: var(--color-pink) !important;
}

.menu_item .social svg:hover path{
    fill: var(--color-pink);
}

.item {
    width: 12px;
    height: 12px;
    background-color: var(--color-primary);
    margin: 5px 30px;
}

.menu_item_content{
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    text-transform: uppercase;
    font-size: 0px;
    margin: 0px 30px;
    transition: all .5s linear;
    color: var(--color-primary);
}

.social{
    left: 0;
    bottom: 0;
    flex-direction: var(--flex-direction-column);
    display: none;
    margin: -2px 0px 25px 10px;
}

.social svg {
    width: 10px;
    height: 10px;
}

.detail {
    text-transform: uppercase;
    font-size: .9rem;
    letter-spacing: 1px;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    color: #fff;
}

.detail svg {
    height: 51px;
    margin-bottom: 17px !important;
    filter:drop-shadow(1px 1px 3px grey);
    z-index: 10;
}

.detail svg, span {
    margin: 10px 0px;
}

.detail span {
    text-shadow: 1px 1px 3px rgba(169,169,169,.5);
}
