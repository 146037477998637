.button{
    border: 1px solid var(--color-light);
    color: var(--color-light);
    background-color: transparent;
    text-transform: uppercase;
    padding: 7px 15px;
    font-family: 'Roboto Slab', sans-serif;
    margin: 0px 10px;
    min-width: 121px;
}

.button:hover{
    background-color: var(--color-light);
    color: var(--color-primary);
}