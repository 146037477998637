.modal {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    z-index: 100;
    opacity: 0.7;
}

.modal svg {
    fill: #fff;
    position: var(--position-absolute);
    top: 20%;
    right: 30%;
    transform: translate(-30%, -20%);
    cursor: var(--cursor-pointer);
}

.children{
    width: 35%;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 100;
    overflow: hidden;
}

.children > div {
    margin: 30px 35px;
    overflow-y: scroll;
    height: 88%;
    padding: 0px 35px;
}
.children > div::-webkit-scrollbar {
    width: 1.5px;
    padding-right: 10px;
}
.children > div::-webkit-scrollbar-track {
    background-color: #cccc;
}
.children > div::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
}

.credits{
    color: var(--color-primary);
}

.credits h3 {
    text-transform: uppercase;
    margin: 35px 0px;
    font-size: var(--font-size-13);
}

.credits p {
    font-size: var(--font-size-12);
}