.expand {
    top: 50vh;
    transform: translateY(-50%) !important;
    margin-left: 15px;
}

.menu {
    left: 2px;
    top: 30px;
}

.custom_text {
    color: var(--color-light) !important;
}