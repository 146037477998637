.dynamic_text{
    padding: 8px 15px;
    background-color: var(--color-background-text);
    color: var(--color-light);
    text-transform: uppercase;
    letter-spacing: 2px;
    width: fit-content;
    height: fit-content;
    z-index: 20;
    opacity: 1;
    animation: appear 2.5s linear alternate;
}

@keyframes appear {
    from { opacity: 0; }
    to { opacity: 1; }
}