.cls-1-blue {
    fill: #332BFF;
}

.cls-1-white {
    fill: #FFFFFF;
}

.cls-1-pink {
    fill: #FF1672;
}