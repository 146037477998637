.react_switch_checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react_switch_label {
    justify-content: space-between;
    width: 19px;
    height: 8px;
    background: #fff;
    border-radius: 100px;
    transition: background-color .2s;
    cursor: var(--cursor-pointer);
    border: 1px solid #fff;
    margin-left: -4px;
  }
  
  .react_switch_label .react_switch_button {
    content: '';
    left: 1px;
    width: 7px;
    height: 7px;
    border-radius: 45px;
    transition: 0.2s;
    background: rgb(51, 51, 51);
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    margin: 0px;
  }
  
  .react_switch_checkbox:checked + .react_switch_label .react_switch_button {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }
  
  .react_switch_label:active .react_switch_button {
    width: 12px;
  }

.container {
    bottom: 70px;
    left: 35px;
    z-index: 20;
}

.container > span {
  color: #fff !important;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: .6rem;
}